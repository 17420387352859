import type { ComponentWithChildren, WithChildren } from 'types/global-types';
import { useAuthCheckQuery, useMetadataQuery } from 'service/auth';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import FullScreenLoader from 'components/FullScreenLoader';
import type { AuthCheckStatusResponse } from 'types/service/auth';
import lang from 'vars/lang';
import type { GeneralMetaData } from 'types/service/metadata';
import MetadataContext from 'contexts/metadata';

type Props = WithChildren;

const AuthCheckWrapper: ComponentWithChildren<Props> = ({ children }) => {
  const {
    data: authCheckResponse, isLoading: isAuthCheckLoading, error: authCheckError,
  } = useAuthCheckQuery();
  const {
    data: metadataResponse,
    isLoading: isMetadataLoading,
  } = useMetadataQuery(undefined, { skip: authCheckResponse?.status !== 'authenticated' });

  const [metadata, setMetadata] = useState<GeneralMetaData | null>(null);

  const isLoading = useMemo(() => isMetadataLoading
  || isAuthCheckLoading, [isAuthCheckLoading, isMetadataLoading]);
  const errorResponse = useMemo(() => authCheckError as AuthCheckStatusResponse, [authCheckError]);
  const shouldRedirect = useMemo(
    () =>
      errorResponse?.status === 'unauthenticated' && errorResponse?.redirectUrl,
    [errorResponse],
  );

  useEffect(() => {
    if (errorResponse) {
      if (errorResponse.status === 'unauthenticated' && errorResponse.redirectUrl) {
        window.location.href = errorResponse.redirectUrl;
      }

      if (errorResponse.status === 'failed'
      || (errorResponse.status === 'unauthenticated' && !errorResponse.redirectUrl)) {
        toast(
          lang.formatString(lang.generics.loginFailed, errorResponse.statusCode ?? 'unknown'),
          { type: 'error' },
        );
      }
    }
  }, [errorResponse]);

  useEffect(() => {
    if (metadataResponse) {
      setMetadata(metadataResponse);
    }
  }, [metadataResponse]);

  useEffect(() => {
    if (authCheckResponse) {
      if (authCheckResponse.status === 'failed') {
        toast(
          lang.formatString(lang.generics.loginFailed, authCheckResponse.statusCode ?? 'unknown'),
          { type: 'error' },
        );
      }
    }
  }, [authCheckResponse]);

  return (
    <MetadataContext.Provider value={metadata}>
      {(isLoading || shouldRedirect) ? (<FullScreenLoader />) : children}
    </MetadataContext.Provider>
  );
};

export default AuthCheckWrapper;
