import type { UserAuthorityRole } from 'types/service/metadata';
import { EnvironmentTypes } from 'types/app-config-types';
import { PaymentIqRoles } from 'types/service/metadata';
import type { Merchants } from 'types/slices/metadata-types';
import type { UserRole, UserRoles } from 'types/user-types';
import { UserFlags } from 'types/user-types';
import { getEnvironment } from 'utils/helpers';

export const getPiqUserRoles = (
  authorities: UserAuthorityRole[],
): PaymentIqRoles[] => authorities.map((a: { authority: PaymentIqRoles }) => a.authority);

export const getUserRoles = (
  userPiqRoles: PaymentIqRoles[],
  userMerchants: Merchants,
  selectedMerchantId: number,
  merchantId?: number,
): UserRoles => {
  const userFlags = getUserFlags(selectedMerchantId, userMerchants, userPiqRoles, merchantId);
  const userRoles = [...userPiqRoles, ...userFlags];
  return userRoles;
};

const getUserFlags = (
  selectedMerchantId: number,
  userMerchants: Merchants,
  userPiqRoles: PaymentIqRoles[],
  merchantId?: number,
): UserFlags[] => {
  const {
    IS_MASTER_MERCHANT,
    HAS_MASTER_MERCHANT,
    IS_ANALYTICS_USER,
    IS_INTERNAL_USER,
    IS_EXPERIMENTAL_USER,
  } = UserFlags;
  const {
    ROLE_OPERATOR_ADMIN,
    ROLE_ANALYTICS,
    ROLE_ANALYTICS_ADMIN,
  } = PaymentIqRoles;
  const userFlags = [];
  if (isMasterMerchant(selectedMerchantId)) userFlags.push(IS_MASTER_MERCHANT);
  if (hasMasterMerchant(userMerchants)) userFlags.push(HAS_MASTER_MERCHANT);
  if (merchantId === 0) userFlags.push(IS_INTERNAL_USER);
  if (hasRequiredUserRoles([ROLE_OPERATOR_ADMIN], userPiqRoles)) {
    userFlags.push(IS_EXPERIMENTAL_USER);
  }
  if (hasSomeUserRole(userPiqRoles, [ROLE_ANALYTICS, ROLE_ANALYTICS_ADMIN])) {
    userFlags.push(IS_ANALYTICS_USER);
  }
  return userFlags;
};

export const isMasterMerchant = (merchantId: number): boolean => merchantId === 0;

export const isPiqTestMerchant = (merchantId: number): boolean => merchantId === 1000
&& [EnvironmentTypes.TEST, EnvironmentTypes.DEV].includes(getEnvironment());

const hasMasterMerchant = (
  userMerchants: Merchants,
): boolean => hasMerchant(userMerchants, 0);

export const hasMerchant = (userMerchants: Merchants, merchantId: number): boolean =>
  userMerchants.some((merchant) => merchant.value === merchantId);

export const hasRequiredUserRoles = (
  requiredRoles: UserRoles,
  userRoles: UserRoles,
): boolean => requiredRoles.every((requiredRole) => hasUserRole(userRoles, requiredRole));

export const hasSomeUserRole = (
  userRoles: UserRoles,
  roles: UserRoles,
) => roles.some((role) => userRoles.includes(role));

export const hasUserRole = (userRoles: UserRoles, role: UserRole): boolean => userRoles.includes(role);
