/* eslint-disable max-len */
import type { LocalizedStringsMethods } from 'react-localization';
import LocalizedStrings from 'react-localization';
import type { PaymentTxStatus } from 'types/service/metadata';

export const composeActionLabel = (
  action: string,
  ...items: string[]
): string => lang.formatString(action, ...items).toString();

interface AppLang extends LocalizedStringsMethods {
  minimizeItem: string;
  clickToGoTo: string;
  copy: string;
  toggle: string;
  addItem: string;
  saveItem: string;
  resetItem: string;
  updateItem: string;
  replaceItem: string;
  configureItem: string;
  createdItem: string;
  addedItem: string;
  newItem: string;
  updatedItem: string;
  deletedItem: string;
  removedItem: string;
  showingItem: string;
  displayItem: string;
  saved: string;
  back: string;
  next: string;
  undoItem: string;
  cancelItem: string;
  deleteItem: string;
  editItem: string;
  failedTo: string;
  signOut: string;
  showItem: string;
  hideItem: string;
  noFound: string;
  enableItem: string;
  disableItem: string;
  enabledItem: string;
  disabledItem: string;
  exportItem: string;
  importItem: string;
  includeItem: string;
  xWasNotYError: string;
  xWasYSuccess: string;
  xIsNotAvailable: string;
  changesOn: string;
  minOne: string;
  perItem: string;
  areYouSureX: string;
  matchItem: string;
  isItem: string;
  products: {
    piq: string;
    cashier: string;
    backoffice: string;
    aiq: string;
    docs: string;
  };
  syntax: {
    conj: {
      cuml: string;
    };
  };
  errors: {
    unspecified: string;
    formHasErrors: string;
  };
  generics: {
    support: string;
    lastDaysCount: string;
    actionCompleted: string;
    approve: string;
    deny: string;
    comment: string;
    confirmItem: string;
    selectReason: string;
    reasonToAction: string;
    cancel: string;
    confirm: string;
    allPendingWithdrawals: string;
    refresh: string;
    remove: string;
    downloadReport: string;
    noData: string;
    never: string;
    loginFailed: string;
    total: string;
    last24Hrs: string;
    successful: string;
    generalHealth: string;
    fetchReport: string;
    transactionsOverview: string;
    transactionsWaitingApproval: string;
    transactionsToApprove: string;
    acceptanceRate: string;
    neverLoggedIn: string;
    shouldResetPassword: string;
    shouldResetPasswordHelpText: string;
    setSecurityKey: string;
    setSecurityKeyHelpText: string;
    setAuth: string;
    setAuthHelpText: string;
    twoFactorRequired: string;
    cannotBeChanged: string;
    history: string;
    numberOfChanges: string;
    twoFCred: string;
    requiredActions: string;
    twoFA: string;
    security: string;
    lastCredentialDeleted: string;
    required: string;
    markPref: string;
    actionNotCompleted: string;
    changesSaved: string;
    unsavedChanges: string;
    clear: string;
    pwdResetLinkSent: string;
    pwdResetLinkError: string;
    updated: string;
    adminApiRolesDoc: string;
    merchants: string;
    roles: string;
    role: string;
    phone: string;
    email: string;
    name: string;
    username: string;
    configuration: string;
    template: string;
    firstName: string;
    lastName: string;
    invalid: string;
    resetPwd: string;
    enabled: string;
    disabled: string;
    lastLogin: string;
    user: string;
    users: string;
    merchant: string;
    merchantId: string;
    mId: string;
    parameter: string;
    pm: string;
    pms: string;
    bin: string;
    ip: string;
    psp: string;
    x3ds2: string;
    fp: string;
    noItemsFound: string;
    refreshOrContactSupport: string;
    jobInformation: string;
    personalInfo: string;
    jobTitle: string;
    contactTypes: string;
    primFin: string;
    secFin: string;
    primCom: string;
    primOp: string;
    secOp: string;
    primSup: string;
    communication: string;
    releaseNotes: string;
    incidentNotes: string;
    marketingNewsletter: string;
    entries: string;
    page: string;
    reset: string;
    continueChangesLost: string;
    stayUpdated: string;
    all: string;
    leaveMerchantsEmpty: string;
    info: string;
    preferences: string;
    backofficeHomepage: string;
    sidebar: string;
    widget: string;
    aiChatbot: string;
    restoreLayout: string;
    homepage: string;
    view: string;
    waiting: string;
    cancelled: string;
    failed: string;
    whatsNew: string;
    load: string;
    locale: string;
    default: string;
    logo: string;
    deleted: string;
    translations: string;
    active: string;
    rule: string;
    preconditions: string;
    transactionId: string;
    note: string;
    shouldOverride: string;
    fileName: string;
    generate: string;
    field: string;
    file: string;
    order: string;
    changes: string;
    status: string;
    conditions: string;
    actions: string;
    not: string;
    item: string;
    items: string;
    revert: string;
    value: string;
    choose: string;
    ruleset: string;
    move: string;
    transaction: string;
    group: string;
  };
  genericsDetails: {
    primFin: string;
    secFin: string;
    primCom: string;
    primOp: string;
    secOp: string;
    primSup: string;
    stayUpdated: string;
    backofficeHomepage: string;
    sidebar: string;
    aiChatbot: string;
    uploadNewLogo: string;
    noNotifications: string;
    configAlertsHere: string;
    restoreLayout: string;
  };
  pages: {
    [key: string]: {
      title: string;
      desc?: string;
      pageHeader?: string;
      pageMessage?: string;
      pageNotice?: string;
    };
  };
  components: {
    [key: string]: {
      [key: string]: string;
    };
  };
  statusCodeDesc: {
    [key in PaymentTxStatus]?: string;
  };
}

const lang: AppLang = new LocalizedStrings({
  en: {
    minimizeItem: 'Minimize {0}',
    clickToGoTo: 'Click to go to {0}',
    copy: 'Create a copy of this {0}',
    toggle: 'Toggle {0}',
    addItem: 'Add {0}',
    saveItem: 'Save {0}',
    updateItem: 'Update {0}',
    replaceItem: 'Replace {0}',
    createdItem: 'Created {0}',
    addedItem: 'Added {0}',
    newItem: 'New {0}',
    updatedItem: 'Updated {0}',
    deletedItem: 'Deleted {0}',
    removedItem: 'Removed {0}',
    showingItem: 'Showing {0}',
    displayItem: 'Display {0}',
    configureItem: 'Configure {0}',
    saved: 'Saved',
    undoItem: 'Undo {0}',
    back: 'Back',
    next: 'Next',
    resetItem: 'Reset {0}',
    cancelItem: 'Cancel {0}',
    deleteItem: 'Delete {0}',
    editItem: 'Edit {0}',
    failedTo: 'Failed to {0}',
    signOut: 'Sign out',
    showItem: 'Show {0} {1}',
    hideItem: 'Hide {0} {1}',
    exportItem: 'Export {0}',
    importItem: 'Import {0}',
    includeItem: 'Include {0}',
    noFound: 'No {0} found',
    enableItem: 'Enable {0}',
    disableItem: 'Disable {0}',
    enabledItem: 'Enabled {0}',
    disabledItem: 'Disabled {0}',
    xWasNotYError: '{0} could not be {1}.',
    xWasYSuccess: '{0} successfully {1}',
    xIsNotAvailable: '{0} is not available',
    changesOn: 'Changes on {0}',
    minOne: 'Please add at least one {0}',
    perItem: 'Per {0}',
    areYouSureX: 'Are you sure you want to {0}?',
    matchItem: 'Match {0}',
    isItem: 'Is {0}',
    products: {
      piq: 'PaymentIQ',
      cashier: 'Cashier',
      backoffice: 'Backoffice',
      aiq: 'AccountIQ',
      docs: 'Documentation',
    },
    syntax: {
      conj: {
        cuml: 'and',
      },
    },
    errors: {
      unspecified: 'Something went wrong. Please try again later.',
      formHasErrors: 'The form has errors',
    },
    generics: {
      support: 'Support',
      approve: 'Approve',
      actionCompleted: '{0} action successfully completed',
      deny: 'Deny',
      cancel: 'Cancel',
      confirm: 'Confirm',
      comment: 'Comment',
      confirmItem: 'Confirm {0}',
      selectReason: 'Select reason',
      reasonToAction: 'Reason to {0}',
      lastDaysCount: 'Last {0} days',
      allPendingWithdrawals: 'All Pending Withdrawals',
      refresh: 'Refresh',
      remove: 'Remove',
      downloadReport: 'Download Report',
      noData: 'No data available',
      minimize: 'Minimize',
      never: 'Never',
      loginFailed: 'Login failed - please contact support. Failure code: {0}',
      total: 'Total',
      acceptanceRate: 'Acceptance Rate',
      generalHealth: 'General Health',
      fetchReport: 'Fetch Report',
      transactionsOverview: 'Transactions Overview',
      transactionsWaitingApproval: 'Transactions Waiting for Approval',
      transactionsToApprove: 'Transactions to approve',
      successful: 'Successful',
      last24Hrs: 'Last 24h',
      neverLoggedIn: 'Never logged in',
      shouldResetPassword: 'User must change password at next logon',
      shouldResetPasswordHelpText: 'The user will be prompted to reset their password when they next log in.',
      setSecurityKey: 'Set up Security Key',
      setSecurityKeyHelpText: 'The user will be prompted to set up a security key when they next log in.',
      setAuth: 'Set up Authenticator Application',
      setAuthHelpText: 'The user will be prompted to set up an authenticator application when they next log in.',
      twoFactorRequired: 'Two-Factor Authentication is required, please select one of the first two options',
      cannotBeChanged: 'cannot be changed',
      history: 'History',
      numberOfChanges: 'Number of changes',
      twoFCred: 'Two-Factor Credentials',
      requiredActions: 'Required actions',
      twoFA: 'Two-Factor Authentication (2FA)',
      security: 'Security',
      lastCredentialDeleted:
        'Last credential was deleted. The user will receive a request to configure the Authenticator application.',
      required: 'required',
      markPref: 'Mark as preferred',
      actionNotCompleted: 'The action could not be performed',
      changesSaved: 'Your changes have been saved',
      unsavedChanges: 'Unsaved changes',
      clear: 'Clear',
      pwdResetLinkSent: 'Password reset link was sent to the user\'s email',
      pwdResetLinkError: 'Failed to send password reset link.',
      updated: 'updated',
      enabled: 'enabled',
      disabled: 'Disabled',
      merchants: 'Merchants',
      roles: 'Roles',
      role: 'role',
      phone: 'Phone',
      email: 'Email',
      name: 'Name',
      username: 'Username',
      configuration: 'Configuration',
      template: 'Template',
      firstName: 'First Name',
      lastName: 'Last Name',
      invalid: 'invalid',
      adminApiRolesDoc: 'the Backoffice User Roles Documentation',
      resetPwd: 'Reset Password',
      lastLogin: 'Last login',
      user: 'User',
      users: 'Users',
      merchant: 'Merchant',
      merchantId: 'Merchant ID',
      mId: 'MID',
      parameter: 'Parameter',
      pm: 'Payment Method',
      pms: 'Payment Methods',
      bin: 'BIN',
      ip: 'IP',
      psp: 'PSP',
      x3ds2: '3DS2',
      fp: 'Fraud Provider',
      refreshOrContactSupport: ' Refresh the page and try again. If the problem persists, contact support.',
      noItemsFound: 'No items found',
      jobInformation: 'Job Information',
      personalInfo: 'Personal Information',
      jobTitle: 'Job Title',
      contactTypes: 'Contact Type(s)',
      primFin: 'Primary Financial Contact',
      secFin: 'Secondary Financial Contact',
      primCom: 'Commercial Contact',
      primOp: 'Primary Operational Contact',
      secOp: 'Secondary Operational Contact',
      primSup: 'Primary Support Contact',
      communication: 'Communication',
      releaseNotes: 'Release Notes',
      incidentNotes: 'Maintenance and Incident Notifications',
      marketingNewsletter: 'Marketing/Newsletter',
      entries: 'entries',
      all: 'All',
      page: 'Page',
      reset: 'Reset',
      continueChangesLost: 'Are you sure you want to continue? Any unsaved changes will be lost.',
      stayUpdated: 'Please update your information',
      leaveMerchantsEmpty:
        'Leave the Merchants field empty to allow access to all merchants within the hierarchy.',
      info: 'Additional information',
      preferences: 'preferences',
      backofficeHomepage: 'Select start page',
      sidebar: 'Sidebar',
      widget: 'Widget',
      aiChatbot: 'AI Chatbot',
      homepage: 'Homepage',
      view: 'View',
      waiting: 'Waiting',
      cancelled: 'Cancelled',
      failed: 'Failed',
      whatsNew: 'What\'s New',
      load: 'Load',
      locale: 'Locale',
      default: 'Default',
      logo: 'Logo',
      deleted: 'Deleted',
      translations: 'Translations',
      restoreLayout: 'Restore Widget layout',
      active: 'Active',
      rule: 'Rule',
      preconditions: 'Preconditions',
      transactionId: 'Transaction Id',
      note: 'Note',
      shouldOverride: 'Override existing id\'s',
      fileName: 'Select file',
      generate: 'Generate',
      field: 'Field',
      file: 'File',
      order: 'Order',
      changes: 'Changes',
      status: 'Status',
      conditions: 'Conditions',
      actions: 'Actions',
      not: 'Not',
      item: 'Item',
      items: 'Items',
      revert: 'Revert',
      value: 'Value',
      choose: 'Choose',
      ruleset: 'Ruleset',
      move: 'Hold and drag to move',
      transaction: 'Transaction',
      group: 'Group',
    },
    genericsDetails: {
      primFin: 'The main point of contact for financial matters.',
      secFin: `Assist the primary financial contact in managing 
      financial matters, and serve as a backup point of contact when necessary.`,
      primCom: 'Managing the business and commercial relationship and agreements.',
      primOp: `The main point of contact for operational matters within an organization 
      or project, and to oversee the day-to-day operations to ensure they run smoothly and efficiently.`,
      secOp: `Support the Primary Operational contact in managing operational matters, and serve as 
      a backup point of contact when necessary to ensure continuity in the operations.`,
      primSup: `Provide assistance and support to end-users, and ensure that their 
      needs and inquiries are addressed promptly and effectively.`,
      stayUpdated: 'You can update your data at any time from the Admin/Backoffice users page.',
      backofficeHomepage: 'Choose what page you would like to see first when you visit PaymentIQ Backoffice. Changes apply after refresh.',
      sidebar: 'Select whether you would like to have the sidebar open or collapsed on desktop devices.',
      aiChatbot: 'By default shows a chatbot helper button in the bottom right corner of the screen.',
      restoreLayout: 'Restore the Home Page Widgets to the default layout',
      uploadNewLogo: 'Upload overrides previously uploaded logo for the selected merchant ID and locale. The page will refresh for an optimal experience.',
      noNotifications: 'No alerts to display at the moment',
      configAlertsHere: 'Configure Alerts Here',
    },
    pages: {
      home: {
        title: 'Home',
      },
      transactions: {
        title: 'Transactions',
        pageNotice: 'This is a Beta version of the new Transaction view. Click here to use the old one.',
      },
      approve: {
        title: 'Approve',
      },
      investigate: {
        title: 'Investigate',
      },
      userAccounts: {
        title: 'User Accounts',
      },
      kyc: {
        title: 'KYC',
      },
      search: {
        title: 'Search',
      },
      block: {
        title: 'Block',
      },
      routing: {
        title: 'Routing',
      },
      fallback: {
        title: 'Fallback',
      },
      tools: {
        title: 'Tools',
      },
      store: {
        title: 'Store',
      },
      product: {
        title: 'Product',
      },
      voucher: {
        title: 'Voucher',
      },
      finance: {
        title: 'Finance',
      },
      settlements: {
        title: 'Settlements',
      },
      balance: {
        title: 'Balance',
      },
      analytics: {
        title: 'Analytics',
      },
      analyticsDefault: {
        title: 'Default',
      },
      analyticsMerchant: {
        title: 'Merchant',
      },
      analyticsPrivate: {
        title: 'Private',
      },
      rules: {
        title: 'Rules',
        desc: 'Adjust the fee for credit cards or set the limit.',
      },
      xRouting: {
        title: '{0} Routing',
      },
      xFallback: {
        title: '{0} Fallback',
      },
      fraudProvider: {
        title: 'Fraud Provider',
      },
      fees: {
        title: 'Fees',
      },
      fee: {
        title: 'Fee',
      },
      providerFee: {
        title: 'Provider Fee',
      },
      limit: {
        title: 'Limit',
      },
      fraudBlocks: {
        title: 'Fraud Blocks',
      },
      xBlock: {
        title: '{0} Block',
      },
      velocity: {
        title: 'Velocity',
      },
      userPspAccount: {
        title: 'User PSP Account',
      },
      forex: {
        title: 'Forex',
      },
      statusCode: {
        title: 'Status Code',
      },
      paymentMethods: {
        title: 'Payment Methods',
      },
      inputs: {
        title: 'Inputs',
      },
      logos: {
        title: 'Logos',
      },
      alerts: {
        title: 'Alerts',
      },
      notification: {
        title: 'Notification',
      },
      approval: {
        title: 'Approval',
      },
      vat: {
        title: 'VAT',
      },
      voidCapture: {
        title: 'Void/Capture',
      },
      admin: {
        title: 'Admin',
      },
      backofficeUsers: {
        title: 'Backoffice Users',
      },
      auditLog: {
        title: 'Audit Log',
      },
      configuration: {
        title: 'Configuration',
      },
      cashier: {
        title: 'Cashier',
      },
      merchant: {
        title: 'Merchant',
      },
      maintenance: {
        title: 'Maintenance',
      },
      apiClients: {
        title: 'API Clients',
      },
      invoice: {
        title: 'Invoice',
      },
      customer: {
        title: 'Customer',
      },
      templates: {
        title: 'Templates',
      },
      emailTemplates: {
        title: 'Email',
      },
      scriptTemplates: {
        title: 'Script',
      },
      htmlTemplates: {
        title: 'HTML',
      },
      sqlTemplates: {
        title: 'SQL',
      },
      mqTemplates: {
        title: 'MQ',
      },
      cssTemplates: {
        title: 'CSS',
      },
      messages: {
        title: 'Messages',
      },
      forexRates: {
        title: 'Forex Rates',
      },
      iin: {
        title: 'IIN',
      },
      bankMapping: {
        title: 'Bank Mapping',
      },
      credentialsTemplate: {
        title: 'Credentials Template',
      },
      documentation: {
        title: 'Documentation',
      },
      aiq: {
        title: 'AccountIQ',
      },
      settings: {
        title: 'Settings',
      },
      myAccount: {
        title: 'My account',
      },
      error: {
        title: 'Error',
        pageHeader: 'Page not found',
        pageMessage: 'Page {0} not found.',
      },
    },
    components: {
      export: {
        shouldExportTableResults: 'Only export the results in the current table',
      },
      mendableButton: {
        canDrag: 'Drag the button to change its place',
        supportLabel: 'Learn About Our AI Chatbot',
      },
      generalHealth: {
        amountDesc: 'The sum of successful deposit transactions within the given period',
        atvDesc: 'The sum of successful deposit transactions divided by the number of successful transactions within the given period',
        accRateDesc: 'The number of successful transactions divided by the number of transactions within the given period',
        successDesc: 'The number of successful deposit transactions within the given period',
        totalDesc: 'The number of successful, failed, and cancelled deposit transactions within the given period',
      },
      xmlConfiguration: {
        updateConfigFailure: 'Failed to update the configuration, please try again later',
        deleteConfigFailure: 'Failed to delete the configuration, please try again later',
        changeNotes: 'Please write a note about the changes that were done.',
        templateConfig: 'Template Configuration',
      },
      homepageWidgets: {
        latestBlogs: 'Latest Blogs',
      },
      rules: {
        targetedRules: 'Targeted Rules',
        match: 'Match transaction against rules',
        matchedIds: 'Found {0}, opening first matched ruleset.',
        newId: 'Generate new rule ID\'s',
        newIdSuccess: 'New IDs generated successfully.',
        newIdNone: 'No IDs to generate.',
        importSuccess: 'Import successful',
        saveOrderTooltip: 'Updates the ruleset priority according to the order in the list.',
        saveOrderSuccess: 'Ruleset priority updated successfully.',
        clearMatched: 'Clear matched rules',
        writeNote: 'Please write a note about the changes that were done.',
        pageNotice: 'This is a Beta version of the new Rules view. Click here to use the old one.',
      },
    },
    statusCodeDesc: {
      ERR_SYSTEM_ERROR: 'This is a non-specific System Error. Contact PaymentIQ Support or your Tech Team',
      ERR_FAILED_TO_CONNECT: 'The system could not connect to the PSP. It might be temporary or indicate a bigger problem. Contact PaymentIQ Support or your Tech Team.',
      ERR_DECLINED_BAD_REQUEST: 'One of the payment parameters was not correct, and the transaction was refused. It is usually an integration issue. Contact PaymentIQ Support or your Tech Team.',
      ERR_DECLINED_FRAUD: 'This usually indicates that the PSP has declined the transaction due to a fraud rule on their side or a Velocity Fraud check in PIQ.',
      ERR_DECLINED_NO_FUNDS: 'This usually means the user does not have funds to complete the transaction. They need to use a different card or method.',
      ERR_DECLINED_ACCOUNT_SUSPENDED: 'The user\'s payment account is not working, and they need to contact their payment institution.',
      ERR_DECLINED_OTHER_REASON: 'This is a non-specific decline reason, but originates with the user\'s payment provider, not from an error. The user should contact their payment Institution.',
      ERR_DECLINED_CONTACT_SUPPORT: 'This is a non-specific decline reason, but originates with the user\'s payment provider, not from an error. The user should contact their payment Institution.',
      ERR_DECLINED_CONFIG_ERROR: 'Declined because of system config error, not because of user input error. This normally means that the provider cannot be used because one or more config parameters are invalid. Contact PaymentIQ Support.',
      ERR_NOT_AUTHENTICATED: 'The user has not been properly authenticated. It could mean they used the wrong details when logging into their bank, eWallet, etc.',
      ERR_INVALID_RESPONSE: 'This is a system error. Please contact PaymentIQ Support or your Tech Team.',
      ERR_DECLINED_REQ_BLOCKED: 'The transaction has been blocked. It might be from the PSP or by a rule in PaymentIQ. Have a look at the \'Info\' column for more insight.',
      ERR_PSP_OUT_OF_SERVICE: 'The PSP seems out of service, and did not respond to the transaction request. If there are other transactions with the same error, you likely need to contact the PSP, but PaymentIQ support might also be able to help.',
      ERR_DECLINED_NOT_AUTHORIZED: 'This is a non-specific decline reason, but originates with the user\'s payment provider, not from an error.',
      ERR_RESPONSE_CODE_UNKNOWN: 'The PSP gave an unknown response. Have a look at the \'Info\' column for more insight.',
      ERR_PSP_ACCOUNT_USED_BY_OTHER_USER: 'The user payment method is already used by someone else.',
      ERR_PSP_ACCOUNT_NOT_USED: 'The user is restricted from withdrawing because they have not used this method to deposit. Your PIQ admin can circumvent this by adding a specific rule in Rules > Fraud Blocks > User PSP Account, or the user should use a different method.',
      ERR_TOO_MANY_PSP_ACCOUNTS: 'The user has too many payment accounts, as defined in Rules > Fraud Blocks > User PSP Account. Ask your PaymentIQ admin what the maximum is.',
      ERR_DECLINED_DUPLICATE_TX_ID: 'Usually means that a transaction was in progress when another similar transaction was started. Ask the user to try again, or contact PaymentIQ Support.',
      ERR_DECLINED_INVALID_ACCOUNT_NUMBER: 'Usually means that the card number or other payment account number was invalid. The user should check their details and try again.',
      ERR_MERCHANT_OUT_OF_SERVICE: 'The merchant (you) is not responding to the API call from PaymentIQ. The server is offline., Contact your Tech Team.',
      ERR_DECLINED_LIMIT_OVERDRAWN: 'Can mean that the user\'s payment method has a limit which they exceeded. It is on the user payment method side, and the user should contact their bank, or use a different method.',
      ERR_MERCHANT_RESPONSE_CODE_UNKNOWN: 'The merchant (you) server is not responding correctly. Contact your Tech team to resolve it.',
      ERR_DECLINED_NO_PROVIDER_FOUND: 'PaymentIQ could not route the payment to a PSP. Check your routing rules.',
      ERR_DECLINED_PROVIDER_ACCOUNT_CONFIG_ERROR: 'PaymentIQ could not route to a PSP account. Check your routing rules or PSP configuration',
      ERR_MERCHANT_INVALID_RESPONSE: 'The merchant (you) is responding to the API call from PaymentIQ strangely. Contact your Tech Team.',
      ERR_ABOVE_LIMIT: 'This is triggered when the transaction amount is above the Limits set in PIQ Rules > Limits. Contact your PaymentIQ admin for details.',
      ERR_BAD_REQUEST: 'This happens when the payment request has some invalid parameters, usually in the integration phase. Contact PaymentIQ Support or your Tech Team.',
      ERR_BELOW_LIMIT: 'This is triggered when the transaction amount is below the Limits set in PIQ Rules > Limits. Contact your PaymentIQ admin for details.',
      ERR_DECLINED_3D_AUTHENTICATION_FAILED: 'The user was unable to verify themselves using 3D secure. They will probably have to contact their bank to troubleshoot it.',
      ERR_DECLINED_BANK_REFUSAL: 'The user\'s bank declined the transaction, and this originates with the user\'s payment provider, not from an error.',
      ERR_DECLINED_CARD_EXPIRED: 'The card being used has expired. This is usually when a saved card has gone out of date. The user will have to re-enter their card details.',
      ERR_DECLINED_CVV_FAIL: 'The user entered the wrong CVV. They need to check their details and try again.',
      ERR_DECLINED_EMAIL_BLACKLIST: 'This usually indicates that the PSP has declined the transaction due to a fraud rule on their side. Contact the PSP about it.',
      ERR_DECLINED_FRAUD_RULE_PSP: 'This usually indicates that the PSP has declined the transaction due to a fraud rule on their side. Contact the PSP about it.',
      ERR_DECLINED_INVALID_AMOUNT: 'The amount sent to the PSP is invalid. This usually happens with PSPs where the user can define or edit the amount during the transaction. It could also possibly be an error in the integration. Contact your Tech team or PaymentIQ Support.',
      ERR_DECLINED_INVALID_ISSUER: 'The card issuer is not supported. It might be an AMEX, JCB, Union Pay, Dankort card, or maybe from a sanctioned country, like Iran.',
      ERR_DECLINED_ISSUER_UNAVAILABLE: 'The transaction could not be processed as the user\'s bank who issued the card is offline and not responding.',
      ERR_DECLINED_LOST_OR_STOLEN: 'The card has been registered as lost or stolen, and the card issuer has blocked it. It is usually a red flag for fraud or further investigation of the user account.',
      ERR_DECLINED_RESTRICTED_CARD: 'This card can not make this type of transaction, the user bank refused it. They will need to use a different card or payment method.',
      ERR_DECLINED_RESTRICTED_TERRITORY: 'The user\'s location is blocking the transaction on the PSP side. They will need to use a different method.',
      ERR_DECLINED_VELOCITY_RULE_PSP: 'Usually indicates that the PSP has declined the transaction due to a velocity rule on their side, like Max transactions/day, etc. Contact the PSP about it.',
      ERR_INVALID_PARAMETER: 'One of the parameters sent in the transaction is invalid or missing. Usually happens during integration and will need solving by your Tech Team or PaymentIQ Support.',
      ERR_IO_EXCEPTION: 'There was a temporary connection issue. The user should try again, or if there are a lot of transactions like this, it is an issue, contact your Tech Team or PaymentIQ Support.',
      ERR_RESPONSIBLE_GAMING_LIMIT: 'The user hit their responsible gaming limits. Advise them accordingly.',
      ERR_USER_ACCOUNT_BLOCKED: 'The user account is blocked on the PSP side. Ask the user to contact their payment institution to resolve it.',
      ERR_USER_SESSION_INVALID: 'The verifyUser API call to the merchant was not verified. Usually happens in the integration phase, but otherwise ask the user to log out and back in again.',
      ERR_DECLINED_3D_VALIDATION_FAILED: 'The 3D secure server used for the user\'s card did not validate the transaction. Usually a temporary issue and the cardholder should try again.',
      ERR_DECLINED_3D_EXPIRED: 'The user did not verify their 3D secure password within the time allowed. They should try again.',
      ERR_VAULTIQ_OUT_OF_SERVICE: 'The vault where PaymentIQ stores card details is not responding. Contact PaymentIQ Support.',
      ERR_DECLINED_IP_BLOCKED: 'The transaction hit an IP blocking rule. This might be at the PSP or in Rules > Fraud Blocks > IP Blocks. Contact the PSP or your PIQ admin.',
      ERR_DECLINED_BIN_BLOCKED: 'The transaction hit a BIN blocking rule. This might be at the PSP or in Rules > Fraud Blocks > BIN Blocks. Contact the PSP or your PIQ admin.',
      ERR_VAULTIQ_UNKNOWN_ACCOUNT: 'The PaymentIQ card storage vault did not find the stored card. You should contact PaymentIQ Support.',
      ERR_DECLINED_KYC_BLOCK: 'The transaction was declined due to a Rule in KYC > Block. Contact your PaymentIQ admin for clarification.',
      ERR_DECLINED_KYC_USER_UNDER_AGE: 'The user was defined as underage for your service. Contact your PaymentIQ admin for clarification.',
      ERR_DECLINED_KYC_CHECK_FAILED: 'The automated KYC check failed. It is either because the KYC service is offline, or not responding. Contact PaymentIQ Support.',
      ERR_DECLINED_BIC_BLOCK: 'The bank transaction is blocked because of the BIC/SWIFT code. It is because of a rule in Rules > Fraud Blocks > BIC Blocks. Contact your PIQ admin.',
      ERR_DECLINED_EXPIRED: 'The transaction expired without the user completing it.',
      ERR_DECLINED_REPEAT_CANCELLED: 'This is a duplicate transaction and has been declined. Ask the user to try again.',
      ERR_DECLINED_CURRENCY_NOT_SUPPORTED: 'This usually means that the PSP is not supporting the currency of the user. The PIQ admin can solve it by adding a Forex rule to swap currencies before processing, or ask the PSP to activate the currency.',
      ERR_DECLINED_FRAUD_SCORE_THRESHOLD_EXCEEDED: 'There was a fraud score that blocked the transaction. It is due to a rule in Rules > FraudBlock > FraudProviderBlock. Ask your PIQ admin for clarification.',
      ERR_DECLINED_MERCHANT_NOT_FOUND: 'The PSP did not find the merchant account. Usually in the integration phase. Check the details in the PSP configuration in PIQ, or check with the PSP for the correct details.',
      ERR_DECLINED_MERCHANT_NOT_ENABLED: 'The PSP recognizes the merchant but has not activated them yet. Contact the PSP and request activation.',
      ERR_DECLINED_PROVIDER_NOT_ENABLED: 'The PSP is not enabled. It probably needs adding to the PIQ config, contact PaymentIQ support.',
      ERR_DECLINED_UNDER_MAINTENANCE: 'This usually happens when the user\'s bank is under maintenance. This should be temporary, ask the user to try again.',
      ERR_NO_REFERRAL_TX_FOUND: 'A transaction created in PIQ (like a refund/void/chargeback) cannot find the original transaction. Check your details, or maybe contact PaymentIQ Support.',
      ERR_DECLINE_TX_NOT_FOUND: 'A transaction created in PIQ (like a refund/void/chargeback) cannot find the original transaction. Check your details, or maybe contact PaymentIQ Support.',
      ERR_DECLINE_COUNTRY_NOT_SUPPORTED: 'The PSP indicates they do not support the user\'s country. Ask the user to move countries, or ask the PSP to add support for this country.',
      ERR_DECLINED_NOT_SUPPORTED_PAYMENT_METHOD_FRAUD: 'The PSP has flagged this as a potentially fraudulent transaction and declined it. Contact the PSP for clarification.',
      ERR_DECLINED_FRAUD_PROVIDER_ACCOUNT_CONFIG_ERROR: 'This transaction could not find the required fraud provider to make the necessary checks. Check the fraud provider routing rules or the PIQ config.',
      ERR_DECLINED_GENERAL_BLOCK: 'This is a general block, usually from a PaymentIQ rule. Contact your PIQ admin to see which rules are blocking the transaction.',
      ERR_READ_TIMEOUT: 'The transaction timed out, but verify it in the PSP back office to see if it is completed.',
      ERR_REFERENCE_MISMATCH: 'The transaction reference was an issue, you need to verify in the PSP back office to see if it was successful.',
      ERR_INCONSISTENT_TRANSACTION: 'The transaction is inconsistent, e.g. funds may have been taken from the user, but not credited to their account. It needs to be manually investigated in the PSP back office.',
      ERR_UNKNOWN_CALLBACK: 'The transaction is inconsistent, e.g. funds may have been taken from the user, but not credited to their account due to a communication error. It needs to be manually investigated in the PSP back office.',
      ERR_UNKNOWN_RESPONSE: 'You need to investigate this one manually. Check the PSP back office to see if the customer transaction was completed.',
    },
  },
});

export default lang;
