import { GET } from 'service/REST';
import BO_API_BASE_URL from 'service/consts';

export const authCheck = (initCheck: boolean): Promise<Response> => GET(
  `${BO_API_BASE_URL}/metadata`,
  undefined,
  initCheck,
);

export const logout = (): Promise<Response> =>
  // returns a 302 redirect to auth.iqservices i.e. the login page via keycloak
  GET('/paymentiq/logout');
