import { colors, assets } from 'vars';

interface AppTheme {
  palette: ThemePalette;
  body: {
    backgroundColor: string;
  };
  splash: {
    backgroundColor: string;
  };
  loader: {
    activeColor: string;
    mutedColor: string;
  };
  label: {
    testEnvironment: string;
    invertedTextColor: string;
    textColor: string;
  };
  sidebar: {
    textColor: string;
    backgroundColor: string;
  };
  logo: {
    src: string;
    invertedSrc: string;
    inverted?: boolean;
  };
}

interface ThemePalette {
  primary: string;
  surface: string;
  headingText: string;
  bodyText: string;
  invertedText: string;
  background: string;
  contrastBackground: string;
  mutedBackground: string;
  border: string;
  blue: string;
}

const defaultPalette: ThemePalette = {
  primary: colors.masterMint,
  surface: colors.coolCoconut,
  headingText: colors.perfectPepper,
  bodyText: colors.grey,
  invertedText: colors.coolCoconut,
  background: colors.coolCoconut,
  contrastBackground: colors.sureSage,
  mutedBackground: colors.lighterLightGrey,
  border: colors.lightGrey,
  blue: colors.friendlyFig,
};

const defaultTheme: (palette?: ThemePalette) => AppTheme = (palette = defaultPalette) => {
  const theme: AppTheme = {
    palette,
    body: {
      backgroundColor: palette.background,
    },
    splash: {
      backgroundColor: palette.mutedBackground,
    },
    loader: {
      activeColor: palette.primary,
      mutedColor: palette.mutedBackground,
    },
    label: {
      testEnvironment: palette.contrastBackground,
      invertedTextColor: palette.invertedText,
      textColor: palette.headingText,
    },
    sidebar: {
      textColor: palette.invertedText,
      backgroundColor: palette.primary,
    },
    logo: {
      src: assets.logo,
      invertedSrc: assets.invertedLogo,
      inverted: true,
    },
  };
  return theme;
};

const appThemes = {
  default: defaultTheme(),
};

export default appThemes.default;
