import type { TransactionData } from 'pages/Transactions/types';
import type { Paths } from 'routing/route-types';
import type { HomePageWidgetItem } from 'types/components/home-page-widgets';
import type { DeepPartial, Nullable } from 'types/global-types';

export enum WebStorageKeys {
  PAYMENTIQ_GENERAL_SETTINGS = 'PAYMENTIQ_GENERAL_SETTINGS',
  PAYMENTIQ = 'PaymentIQ',
}

// if more properties are added - make these optional to allow
// partial updated. For example when changing merchantId, you can send in an object
// that only contains merchantId and not the other properties
export type PaymentIQGeneralSettings = {
  merchantId?: number;
};

type OnSort<T> = {
  sortCol: {
    id: keyof T;
    field: keyof T;
  };
  sortAsc: boolean;
};

export type TableColumnsPreference<T> = Partial<{
  [key in keyof T]: TableColumnPreference<T>;
}>;

export type TableColumnPreference<T> = {
  id: keyof T;
  visible?: boolean;
  width?: number;
  index?: number;
};

export type PagePreferences = Partial<{
  transaction: PagePreference<TransactionData>;
}>;

type PagePreference<T> = Partial<{
  onSort: Nullable<OnSort<T>>;
  columns: TableColumnsPreference<T>;
}>;

type AppPreferences = DeepPartial<{
  merchant: {
    selected: number;
  };
  backofficeHomepage: Paths;
  sidebar: string;
  showAiChatbot: boolean;
  restoreDefaultWidgetsLayout: boolean;
  decisiontable: {
    hideDisabledRules: boolean;
  };
}>;

export type PaymentIQPreferences = Partial<{
  settings: AppPreferences;
  grid: PagePreferences;
  homePageLayout: HomePageWidgetItem[];
  isGeneralHealthHidden: boolean;
}>;
