import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'service/rtkQuery';
import type { GeneralMetaData } from 'types/service/metadata';
import type { AuthCheckStatusResponse } from 'types/service/auth';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    authCheck: builder.query<AuthCheckStatusResponse, void >({
      query: () => '/paymentiq/backoffice/api/metadata',
      transformErrorResponse(_, meta) {
        const status = meta?.response?.status;

        if (status === 401) {
          const authenticateHeader = meta?.response?.headers?.get('www-authenticate');
          const oAuthPath = authenticateHeader ? authenticateHeader
            .split('authorization_uri="')[1]
            .replace(/['"]+/g, '') : undefined;

          return { status: 'unauthenticated', redirectUrl: oAuthPath, statusCode: status };
        }

        return { status: 'failed', statusCode: status };
      },
      transformResponse(_, meta) {
        const status = meta?.response?.status;

        if (status === 200) {
          return { status: 'authenticated' };
        }

        return { status: 'failed', statusCode: status };
      },
    }),
    metadata: builder.query<GeneralMetaData, void>({
      query: () =>
        '/paymentiq/backoffice/api/metadata',
    }),
    logout: builder.query({
      query: () => '/paymentiq/logout',
    }),
  }),
});

export const {
  useMetadataQuery,
  useAuthCheckQuery,
} = authApi;
