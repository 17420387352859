import { AuthApi } from 'service';
import type { AppConfig } from 'types/app-config-types';
import { EnvironmentTypes } from 'types/app-config-types';
import type { AuthStatus } from 'types/global-types';
import type { GeneralMetaData, PaymentIqRoles } from 'types/service/metadata';
import type { PaymentIQGeneralSettings } from 'types/web-storage-types';
import { getWebStorage } from 'utils/web-storage';
import { WebStorageKeys } from 'types/web-storage-types';
import type { MenuItem, MenuItemSubItemSections } from 'types/menu-items-types';
import { Paths, type Pages } from 'routing/route-types';
import appConfig from 'app-config';
import type { UserRoles } from 'types/user-types';
import type { Path } from 'react-router-dom';
import { hasRequiredUserRoles } from 'utils/user';
import type { AppDispatch } from 'store/store';
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit';

const redirectToLogin = (loginPath: string): void => {
  window.location.href = `/${loginPath}`;
};

export const getEnvironment = (): EnvironmentTypes =>
  (process.env.REACT_APP_ENV as EnvironmentTypes) || EnvironmentTypes.PRODUCTION;
export const getEnvConfig: (
  env?: EnvironmentTypes
  ) => AppConfig[EnvironmentTypes] = (env = getEnvironment()) =>
    appConfig[env];

export const checkIsAuthenticated = async (checkAuth = false): Promise<AuthStatus> => {
  const authResponse = await AuthApi.authCheck(checkAuth);
  const resData = {
    statusCode: authResponse.status,
  };
  if (authResponse.status !== 200) {
    const authenticateHeader: string | null = authResponse.headers.get('www-authenticate');
    if (authenticateHeader) {
      const oAuthPath = authenticateHeader
        .split('authorization_uri="')[1]
        .replace(/['"]+/g, ''); // split to get the path, and strip away trailing comma

      return {
        isAuthenticated: false,
        toAuthPath: () => redirectToLogin(oAuthPath),
        checkAuth,
      };
    }
  } else if (authResponse.status === 200) {
    const metadata = await authResponse.json() as GeneralMetaData;
    return {
      ...resData,
      isAuthenticated: true,
      metadata,
    };
  } else {
    return {
      ...resData,
      isAuthenticated: false,
      statusCode: authResponse.status,
    };
  }

  // if all else fails -> return false
  return {
    isAuthenticated: false,
  };
};

export const getSelectedMerchantId = (): number | false => {
  // First load the localStorage value will be undefined
  const piqSettings: PaymentIQGeneralSettings | false = getWebStorage(
    WebStorageKeys.PAYMENTIQ_GENERAL_SETTINGS,
  );
  if (piqSettings && piqSettings.merchantId) {
    return piqSettings.merchantId;
  }
  return false;
};

export const getLegacyBaseUrl = (): string => getEnvConfig().legacyBackofficeUrl;

const verifyRoles = (
  requiredRoles: UserRoles | null,
  validRoles: PaymentIqRoles[] | null,
  invalidRoles: PaymentIqRoles[] | null | undefined,
  userRoles: UserRoles,
): boolean => {
  type CallbackType = (role: PaymentIqRoles) => boolean;
  const checkRoles = (itemRoles: PaymentIqRoles[], callback: CallbackType) =>
    itemRoles.some(callback);
  let isValid = true;
  if (requiredRoles && !hasRequiredUserRoles(requiredRoles, userRoles)) return false;
  if (validRoles) {
    // Check if user has any of the these validRoles
    const hasValidRole = checkRoles(validRoles, (role) =>
      userRoles?.includes(role));
    if (!hasValidRole) isValid = false;
  } else if (invalidRoles) {
    // Check if user DOES NOT have any of these invalidRoles
    const hasInvalidRole = checkRoles(
      invalidRoles,
      (role) => !userRoles.includes(role),
    );
    if (!hasInvalidRole) isValid = false;
  }
  return isValid;
};

export const hasValidRoles = (
  item: MenuItem|MenuItemSubItemSections,
  userRoles: UserRoles,
): boolean => verifyRoles(item.requiredRoles ?? null, item.validRoles, item.invalidRoles, userRoles);

export const handleLegBoRoute = (
  setShowLegacy: ActionCreatorWithPayload<boolean, 'page/setShowLegacy'>,
  dispatch: AppDispatch,
  viewId: Pages,
  show: boolean,
): void => {
  dispatch(setShowLegacy(show));
};

export const routeWithQueryParams = (route?: string): Omit<Path, 'hash'> | null =>
  (route ? { pathname: route, search: window.location.search } : null);

/**
 * @param enumValue - a url path. E.g /templates/email
 * Returns the enum key (as string) matching the enumValue. E.g ADMIN__TEMPLATES__EMAIL
 */
export const getRoutePathKeyByValue = (enumValue: string): string => {
  const keys = Object.keys(Paths).filter(
    (enumProp) => Paths[enumProp as keyof typeof Paths] === enumValue,
  );
  return keys.length > 0 ? keys[0] : '';
};

/**
 * @param pathname - A URL pathname, e.g. /analytics/dashboard-1
 * Returns appropriately formatted page ID, e.g. ANALYTICS__DASHBOARD-1
 */
export const generatePageId = (pathname: string): Pages => {
  if (pathname.charAt(0) === '/') pathname = pathname.substring(1);
  pathname = pathname.replaceAll('/', '__').toUpperCase();
  return pathname as Pages;
};

export const getPrettyPageTitle = (path: Pages): string => {
  const splitByDoubleUnderscore: string[] = path && path?.split('__');
  return path && splitByDoubleUnderscore
    .map((val: string) => val.replaceAll('_', ' ').toLocaleLowerCase()).join('/');
};

export const formatDate = (
  dateString: string,
  options?: Partial<{
    formatOptions: Intl.DateTimeFormatOptions;
    prettyFormat: boolean;
  }>,
  locale?: string,
): string => {
  const date = new Date(dateString);
  const language = locale ?? 'sv-SE'; // ISO 8601
  const prettyFormatOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZoneName: undefined,
    timeZone: undefined,
    minute: undefined,
    hour: undefined,
    second: undefined,
    ...options?.formatOptions,
  };
  const formatOptions: Intl.DateTimeFormatOptions = {
    month: 'numeric',
    year: 'numeric',
    day: 'numeric',
    second: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: 'UTC',
    ...options?.formatOptions,
  };
  return new Intl.DateTimeFormat(
    options?.prettyFormat && !locale ? 'en-GB' : language,
    options?.prettyFormat ? prettyFormatOptions : formatOptions,
  )
    .format(date);
};

export const capitalFirst = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const invalidEmail = (value: string): boolean => { // e-mail validation, same from old BO
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,50}$/;
  // @see http://www.regular-expressions.info/email.html
  return (value?.length > 0 && !regex.test(value?.toUpperCase()));
};

export const validPhone = (value: string): boolean => { // phone validation
  const regex = /^(?:\+|00)?(\s?\d\s?){1,17}$/i;
  return (regex.test(value?.toUpperCase()));
};

export const validUrl = (url: string) => {
  if (url === '') {
    return true;
  }
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  return pattern.test(url);
};

export const isValidJson = (str: string) => {
  try {
    const data = JSON.parse(str);
    return data;
  } catch (e) {
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty(obj: any) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return Object.entries(obj).length === 0;
}
