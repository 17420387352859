import { toast } from 'react-toastify';
import logError from 'utils/error';
import { checkIsAuthenticated, getEnvConfig, validUrl } from 'utils/helpers';
import lang from 'vars/lang';

enum MethodTypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'delete',
}

const getApiUrl = (url: string): string => `${getEnvConfig().boApiEndpoint}${url}`;

const handle401 = async (checkAuth: boolean) => {
  const authRes = await checkIsAuthenticated(checkAuth);
  if (authRes.toAuthPath) {
    // eslint-disable-next-line no-console
    console.log('authRes.toAuthPath', authRes.toAuthPath);
    authRes.toAuthPath();
  }
};

interface FetchMethodParams {
  path: string;
  method: MethodTypes;
  payload?: null | string | FormData;
  defineHeader?: boolean;
  checkAuth?: boolean;
  hideErrorNotification?: boolean;
}

const notify = () => toast(lang.errors.unspecified, {
  type: 'error',
  autoClose: false,
});

const fetchMethod = async ({
  path,
  method,
  payload = null,
  defineHeader = true,
  checkAuth = false,
  hideErrorNotification = false,
}: FetchMethodParams): Promise<Response> => {
  try {
    const res = await fetch(path, {
      method,
      ...defineHeader && {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      ...(payload && { body: payload }),
    });

    // Handle 401 (not during inital load though, we have that in index.tsx)
    if (!checkAuth && res.status !== 200) {
      await handle401(true);
    }

    return res;
  } catch (err) {
    logError('Failed fetching');
    if (!hideErrorNotification) notify();

    throw err;
  }
};

export const GET = async (url: null|string, fullUrl = '', checkAuth = false): Promise<Response> => {
  const getUrl = url ? getApiUrl(url) : fullUrl;
  const res = await fetchMethod({
    path: getUrl,
    method: MethodTypes.GET,
    checkAuth,
  });

  return res;
};

export const POST = async (
  url: string,
  body: unknown,
  isFormData = false,
  hideErrorNotification: boolean = false,
): Promise<Response> => {
  const getUrl = validUrl(url) ? url : getApiUrl(url);
  const res = await fetchMethod({
    path: getUrl,
    method: MethodTypes.POST,
    payload: (isFormData && body) ? (body as FormData) : JSON.stringify(body),
    defineHeader: !isFormData,
    hideErrorNotification,
  });
  return res;
};

export const PUT = async (url: string, body: unknown, defineHeader?: boolean): Promise<Response> => {
  const res = await fetchMethod({
    path: getApiUrl(url),
    method: MethodTypes.PUT,
    payload: returnAsString(body),
    defineHeader,
  });

  return res;
};

export const DELETE = async (url: string): Promise<Response> => {
  const res = await fetchMethod({
    path: getApiUrl(url),
    method: MethodTypes.DELETE,
  });
  return res;
};

const returnAsString = (obj: unknown) => {
  if (typeof obj === 'string') {
    return obj;
  }
  return JSON.stringify(obj);
};
