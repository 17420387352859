import { GET } from 'service/REST';
import BO_API_BASE_URL from 'service/consts';
import type { MerchantMetaData, GeneralMetaData } from 'types/service/metadata';

export const fetchGeneralMetadata = async <T = GeneralMetaData>(): Promise<T> => {
  const response = await GET(`${BO_API_BASE_URL}/metadata`);
  return response.json() as Promise<T>;
};

export const fetchMerchantMetadata = async <T = MerchantMetaData>(merchantId: number): Promise<T> => {
  const response = await GET(`${BO_API_BASE_URL}/metadata/${merchantId}`);
  return response.json() as Promise<T>;
};
