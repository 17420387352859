import { authCheck, logout } from 'service/auth/auth-service';
import {
  fetchGeneralMetadata,
  fetchMerchantMetadata,
} from 'service/metadata/metadata-service';
import {
  fetchTemplates,
  fetchTemplateHistory,
  updateTemplate,
  deleteTemplate,
  createTemplate,
} from 'service/templates/templates-service';

const AuthApi = {
  authCheck,
  logout,
};

const MetadataApi = {
  fetchGeneralMetadata,
  fetchMerchantMetadata,
};

const TemplateApi = {
  fetchTemplates,
  fetchTemplateHistory,
  updateTemplate,
  deleteTemplate,
  createTemplate,
};

export { AuthApi, MetadataApi, TemplateApi };
