import type {
  PaymentIQGeneralSettings,
} from 'types/web-storage-types';
import { WebStorageKeys } from 'types/web-storage-types';

export const setPaymentIqGeneralSettings = (
  newValue: PaymentIQGeneralSettings,
): void => {
  const currentSettings: PaymentIQGeneralSettings | false = getWebStorage(
    WebStorageKeys.PAYMENTIQ_GENERAL_SETTINGS,
  );
  setWebStorage(WebStorageKeys.PAYMENTIQ_GENERAL_SETTINGS, {
    ...(currentSettings || {}),
    ...newValue,
  });
};

export const getWebStorage = (
  key: WebStorageKeys,
): PaymentIQGeneralSettings | false => {
  const data: string | null = sessionStorage.getItem(key) || localStorage.getItem(key);
  return data ? JSON.parse(data) as PaymentIQGeneralSettings : false;
};

export const setWebStorage = (
  key: WebStorageKeys,
  value: string | number | Record<string, unknown>,
): void => {
  const data: string = typeof value === 'string' ? value : JSON.stringify(value);
  sessionStorage.setItem(key, data);
  localStorage.setItem(key, data);
};
