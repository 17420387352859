import type { AppConfig } from 'types/app-config-types';
import { EnvironmentTypes } from 'types/app-config-types';

const oldBoVersion = '1.0.224';

const appConfig: AppConfig = {
  [EnvironmentTypes.PRODUCTION]: {
    legacyBackofficeUrl: `${window.location.origin}/paymentiq/bo/?version=${oldBoVersion}`,
    boApiEndpoint: window.location.origin,
    piqApiEndpoint: 'https://api.paymentiq.io',
    documentationUrl: 'https://docs.paymentiq.io',
    oldBoVersion,
    logoutUrl: `${window.location.origin}/paymentiq/logout`,
    aiqBoBaseUrl: 'https://backoffice.accountiq.io',
    aiqLandingPageUrl: 'https://home.accountiq.io',
    mendableAnonKey: 'e71931b5-c295-4226-ad00-b3de8724c5fe',
    countlyAppKey: '44a5cdfd9cddb5d3d85c3d8f2e9ce4a1b291adbd',
    countlyUrl: 'https://countly.iqservices.io/',
  },
  [EnvironmentTypes.TEST]: {
    legacyBackofficeUrl: `${window.location.origin}/paymentiq/bo/?version=${oldBoVersion}`,
    boApiEndpoint: window.location.origin,
    piqApiEndpoint: 'https://test-api.paymentiq.io',
    documentationUrl: 'https://test-docs.paymentiq.io',
    oldBoVersion,
    logoutUrl: `${window.location.origin}/paymentiq/logout`,
    aiqBoBaseUrl: 'https://backoffice.accountiq.io',
    aiqLandingPageUrl: 'https://home.accountiq.io',
    mendableAnonKey: 'e71931b5-c295-4226-ad00-b3de8724c5fe',
    countlyAppKey: '2f7f09b12d35b6420873dae05a3e4a81efcd867a',
    countlyUrl: 'https://test-countly.iqservices.io',
  },
  [EnvironmentTypes.DEV]: {
    legacyBackofficeUrl: `${window.location.origin}/paymentiq/bo/?version=${oldBoVersion}`,
    boApiEndpoint: window.location.origin,
    piqApiEndpoint: 'https://test-api.paymentiq.io',
    documentationUrl: 'https://test-docs.paymentiq.io',
    oldBoVersion,
    logoutUrl: `${window.location.origin}/paymentiq/logout`,
    aiqBoBaseUrl: 'https://backoffice.aiq-test.click',
    aiqLandingPageUrl: 'https://home.accountiq.io',
    mendableAnonKey: 'e71931b5-c295-4226-ad00-b3de8724c5fe',
    countlyAppKey: '',
    countlyUrl: '',
  },
};

export default appConfig;
