const host = 'https://static.paymentiq.io';

const assets = {
  logo: `${host}/Worldline-Mint-Horizontal.svg`,
  invertedLogo: `${host}/Worldline-Coconut-Horizontal.svg`,
  aiqLogo: `${host}/aiq.svg`,
  aiqLogoInverted: `${host}/aiq-mint.svg`,
};

export default assets;
