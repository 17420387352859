import {
  GET, POST, DELETE, PUT,
} from 'service/REST';
import BO_API_BASE_URL from 'service/consts';
import type {
  FetchTemplatesParameters,
  FetchTemplateHistoryParameters,
  CreateTemplateParameters,
  UpdateTemplateParameters,
  DeleteTemplateParameters,
  TemplateResponse,
  TemplateHistoryResponse,
  CreateUpdateTemplateResponse,
} from 'types/service/templates';

export const fetchTemplates = async <T = TemplateResponse>(
  parameters: FetchTemplatesParameters): Promise<T> => {
  const { type, merchantId } = parameters;
  const response = await GET(
    `${BO_API_BASE_URL}/template/${type}/read?merchantId=${merchantId}&start=0&limit=50`,
  );

  return response.json() as Promise<T>;
};

export const fetchTemplateHistory = async <T = TemplateHistoryResponse>(
  parameters: FetchTemplateHistoryParameters,
): Promise<T> => {
  const { templateType, merchantId, limit = '30' } = parameters;
  const response = await GET(
    `${BO_API_BASE_URL}/template/html/history/${templateType}?limit=${limit}&merchantId=${merchantId}`,
  );

  return response.json() as Promise<T>;
};

export const createTemplate = async <T = CreateUpdateTemplateResponse>(
  parameters: CreateTemplateParameters,
): Promise<T> => {
  const { merchantId, payload } = parameters;
  const response = await POST(`${BO_API_BASE_URL}/template/?merchantId=${merchantId}`, payload);

  return response.json() as Promise<T>;
};

export const updateTemplate = async <T = CreateUpdateTemplateResponse>(
  parameters: UpdateTemplateParameters,
): Promise<T> => {
  const { merchantId, payload } = parameters;
  const response = await PUT(
    `${BO_API_BASE_URL}/template/${payload.id}/?merchantId=${merchantId}`,
    payload,
  );

  return response.json() as Promise<T>;
};

export const deleteTemplate = async <T = TemplateResponse>(
  parameters: DeleteTemplateParameters,
): Promise<T> => {
  const { merchantId, templateId } = parameters;
  const response = await DELETE(`${BO_API_BASE_URL}/template/${templateId}?merchantId=${merchantId}`);

  return response.json() as Promise<T>;
};
