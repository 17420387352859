import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import 'semantic-ui-less/semantic.less';
import FullScreenLoader from 'components/FullScreenLoader';
import AuthCheckWrapper from 'components/auth-check-wrapper';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

// Dynamic imports
const App = React.lazy(() => import('./App'));
// Lazy-load the store to not display any default store values if not authenticated (OWASP best practices)
const StoreProvider = React.lazy(() => import('components/StoreProvider'));

const container = document.getElementById('root');
const root = createRoot(container!);

const renderReactDOM = (Content: React.ComponentType) => {
  root.render(<Content />);
};

renderReactDOM(() => (
  <Suspense fallback={<FullScreenLoader />}>
    <StoreProvider content={(
      <AuthCheckWrapper>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </AuthCheckWrapper>
  )}
    />
  </Suspense>
));

serviceWorkerRegistration.unregister();
