import { Link } from 'react-router-dom';
import type { SemanticSIZES } from 'semantic-ui-react';
import {
  Image,
  Grid,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { routeWithQueryParams } from 'utils/helpers';
import type { FunctionalComponent } from 'types/global-types';
import appTheme from 'theme-config';

interface Props {
  size?: SemanticSIZES;
  inverted?: boolean;
  asLink?: boolean;
  noHeader?: boolean;
}

const StyledLogo = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
});

const LogoHeader = styled.span({
  fontSize: '0.85em',
  fontWeight: 500,
});

const Logo: FunctionalComponent<Props> = ({
  size,
  inverted = false,
  asLink = false,
  noHeader = false,
}) => {
  const getLogoSrc = () => (inverted && appTheme.logo.invertedSrc
    ? appTheme.logo.invertedSrc
    : appTheme.logo.src);

  return (
    <StyledLogo>
      <Grid style={{ marginTop: '0px' }}>
        <Grid.Row>
          <Image
            as={asLink ? Link : null}
            to={asLink ? routeWithQueryParams('/') : null}
            size={size}
            width={168}
            src={getLogoSrc()}
          />
        </Grid.Row>
        <Grid.Row>
          {!noHeader && (
          <div style={{ marginTop: '-25px' }}>
            <LogoHeader>PaymentIQ</LogoHeader>
          </div>
          )}
        </Grid.Row>
      </Grid>
    </StyledLogo>
  );
};

export default Logo;
