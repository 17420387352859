import styled from 'styled-components';
import appTheme from 'theme-config';
import Logo from 'elements/Logo';
import type { ReactNode } from 'react';

const Wrapper = styled.div({
  display: 'flex',
  height: '100vh',
  backgroundColor: appTheme.splash.backgroundColor,
});

const LogoWrapper = styled.div({
  margin: '0 auto !important',
  height: '200px',
  alignSelf: 'center',
});

const FullScreenLoader = (): ReactNode => (
  <Wrapper>
    <LogoWrapper>
      <Logo noHeader size="medium" />
    </LogoWrapper>
  </Wrapper>
);

export default FullScreenLoader;
