// The structure of the Pages name indicated the folder structure.
// if a part of the name is wrapped in double underscores it means its a folder
// TOOLS__KYC__SEARCH means that Tools and KYC are folders and inside KYC there's a link to Search
// We do this to auto-open the menu to the currently active menu item on load

// Split folders by double underscore and single words with single underscore.

import type { PageRoles } from 'types/menu-items-types';
import type { FunctionalComponent, PageProps } from 'types/global-types';

export enum Pages {
  HOME = 'HOME',
  ERROR = 'ERROR',
  TRANSACTIONS = 'TRANSACTIONS',
  LEGACY__TRANSACTIONS = 'LEGACY__TRANSACTIONS',
  APPROVE = 'APPROVE',
  INVESTIGATE = 'INVESTIGATE',
  FINANCE__SETTLEMENTS = 'FINANCE__SETTLEMENTS',
  FINANCE__BALANCE = 'FINANCE__BALANCE',
  USER_ACCOUNTS = 'USER_ACCOUNTS',
  ES_TRANSACTIONS = 'ES_TRANSACTIONS',
  ES_APPROVE = 'ES_APPROVE',
  ES_INVESTIGATE = 'ES_INVESTIGATE',
  KYC__SEARCH = 'KYC__SEARCH',
  KYC__BLOCK = 'KYC__BLOCK',
  KYC__ROUTING = 'KYC__ROUTING',
  KYC__FALLBACK = 'KYC__FALLBACK',
  TOOLS__STORE__PRODUCT = 'TOOLS__STORE__PRODUCT',
  TOOLS__STORE__VOUCHER = 'TOOLS__STORE__VOUCHER',
  RULES__ROUTING__PSP_ROUTING = 'RULES__ROUTING__PSP_ROUTING',
  LEGACY__RULES__ROUTING__PSP_ROUTING = 'LEGACY__RULES__ROUTING__PSP_ROUTING',
  RULES__ROUTING__PSP_FALLBACK = 'RULES__ROUTING__PSP_FALLBACK',
  RULES__ROUTING__FRAUD_PROVIDER = 'RULES__ROUTING__FRAUD_PROVIDER',
  RULES__ROUTING__3DS2_ROUTING = 'RULES__ROUTING__3DS2_ROUTING',
  RULES__ROUTING__3DS2_FALLBACK = 'RULES__ROUTING__3DS2_FALLBACK',
  RULES__FEES__FEE = 'RULES__FEES__FEE',
  RULES__FEES__PROVIDER_FEE = 'RULES__FEES__PROVIDER_FEE',
  RULES__LIMIT = 'RULES__LIMIT',
  RULES__FRAUD_BLOCKS__GENERAL_BLOCK = 'RULES__FRAUD_BLOCKS__GENERAL_BLOCK',
  RULES__FRAUD_BLOCKS__IP_BLOCK = 'RULES__FRAUD_BLOCKS__IP_BLOCK',
  RULES__FRAUD_BLOCKS__BIN_BLOCK = 'RULES__FRAUD_BLOCKS__BIN_BLOCK',
  RULES__FRAUD_BLOCKS__VELOCITY = 'RULES__FRAUD_BLOCKS__VELOCITY',
  RULES__FRAUD_BLOCKS__USER_PSP_ACCOUNT = 'RULES__FRAUD_BLOCKS__USER_PSP_ACCOUNT',
  RULES__FRAUD_BLOCKS__FRAUD_PROVIDER_BLOCK = 'RULES__FRAUD_BLOCKS__FRAUD_PROVIDER_BLOCK',
  RULES__FOREX = 'RULES__FOREX',
  RULES__STATUS_CODE = 'RULES__STATUS_CODE',
  RULES__PAYMENT_METHODS = 'RULES__PAYMENT_METHODS',
  RULES__ALERTS = 'RULES__ALERTS',
  RULES__NOTIFICATION = 'RULES__NOTIFICATION',
  RULES__APPROVAL = 'RULES__APPROVAL',
  RULES__VAT = 'RULES__VAT',
  RULES__VOID_CAPTURE = 'RULES__VOID_CAPTURE',
  ADMIN__BACKOFFICE_USERS = 'ADMIN__BACKOFFICE_USERS',
  ADMIN__AUDIT_LOG = 'ADMIN__AUDIT_LOG',
  ADMIN__CONFIGURATION_WIZARD = 'ADMIN__CONFIGURATION_WIZARD',
  ADMIN__CONFIGURATION = 'ADMIN__CONFIGURATION',
  ADMIN__MERCHANT = 'ADMIN__MERCHANT',
  ADMIN__MAINTENANCE = 'ADMIN__MAINTENANCE',
  ADMIN___API_CLIENTS = 'ADMIN___API_CLIENTS',
  ADMIN__INVOICE = 'ADMIN__INVOICE',
  ADMIN__CUSTOMER = 'ADMIN__CUSTOMER',
  ADMIN__TEMPLATES__EMAIL = 'ADMIN__TEMPLATES__EMAIL',
  ADMIN__TEMPLATES__SCRIPT = 'ADMIN__TEMPLATES__SCRIPT',
  ADMIN__TEMPLATES__HTML = 'ADMIN__TEMPLATES__HTML',
  ADMIN__TEMPLATES__SQL = 'ADMIN__TEMPLATES__SQL',
  ADMIN__TEMPLATES__MQ = 'ADMIN__TEMPLATES__MQ',
  ADMIN__TEMPLATES__CSS = 'ADMIN__TEMPLATES__CSS',
  ADMIN__MESSAGES = 'ADMIN__MESSAGES',
  ADMIN__FOREX_RATES = 'ADMIN__FOREX_RATES',
  ADMIN__IIN = 'ADMIN__IIN',
  ADMIN__BANK_MAPPING = 'ADMIN__BANK_MAPPING',
  ADMIN__CREDENTIALS_TEMPLATE = 'ADMIN__CREDENTIALS_TEMPLATE',
  ADMIN__CASHIER__PAYMENT_METHOD_LOGOS = 'ADMIN__CASHIER__PAYMENT_METHOD_LOGOS',
  ADMIN__CASHIER__PAYMENT_METHODS_AND_INPUTS = 'ADMIN__CASHIER__PAYMENT_METHODS_AND_INPUTS',
  TOOLS = 'TOOLS',
  KYC = 'KYC',
  STORE = 'STORE',
  FINANCE = 'FINANCE',
  ANALYTICS = 'ANALYTICS',
  RULES = 'RULES',
  LEGACY__RULES = 'LEGACY__RULES',
  ROUTING = 'ROUTING',
  FEES = 'FEES',
  FRAUD_BLOCKS = 'FRAUD_BLOCKS',
  ADMIN = 'ADMIN',
  TEMPLATES = 'TEMPLATES',
  CASHIER = 'CASHIER',
  SETTINGS = 'SETTINGS',
  DOCUMENTATION = 'DOCUMENTATION',
  ACCOUNTIQ = 'ACCOUNTIQ',
  MY_ACCOUNT = 'MY_ACCOUNT',
  SIGN_OUT = 'SIGN_OUT',
  SUPPORT = 'SUPPORT',
}

export enum Paths {
  HOME = '/home',
  TRANSACTIONS = '/transactions',
  LEGACY__TRANSACTIONS = '/legacy/transactions',
  APPROVE = '/approve',
  INVESTIGATE = '/investigate',
  FINANCE__SETTLEMENTS = '/finance/settlements',
  FINANCE__BALANCE = '/finance/balance',
  USER_ACCOUNTS = '/user-accounts',
  SETTINGS = '/settings',
  ANALYTICS = '/analytics/:dashboardId',
  KYC__SEARCH = '/kyc/search',
  KYC__BLOCK = '/kyc/block',
  KYC__ROUTING = '/kyc/routing',
  KYC__FALLBACK = '/kyc/fallback',
  TOOLS__STORE__PRODUCT = '/product/read',
  TOOLS__STORE__VOUCHER = '/voucher/read',
  RULES__ROUTING__PSP_ROUTING = '/routing/psp-routing',
  LEGACY__RULES__ROUTING__PSP_ROUTING = '/legacy/routing/psp-routing',
  RULES__ROUTING__PSP_FALLBACK = '/routing/psp-fallback',
  RULES__ROUTING__FRAUD_PROVIDER = '/routing/fraud-provider',
  RULES__ROUTING__3DS2_ROUTING = '/routing/3ds2-routing',
  RULES__ROUTING__3DS2_FALLBACK = '/routing/3ds2-fallback',
  RULES__FEES__FEE = '/fees/fee',
  RULES__FEES__PROVIDER_FEE = '/fees/provider-fee',
  RULES__LIMIT = '/rules/limit',
  RULES__FRAUD_BLOCKS__GENERAL_BLOCK = '/fraud-block/general-block',
  RULES__FRAUD_BLOCKS__IP_BLOCK = '/fraud-block/ip-block',
  RULES__FRAUD_BLOCKS__BIN_BLOCK = '/fraud-block/bin-block',
  RULES__FRAUD_BLOCKS__VELOCITY = '/fraud-block/velocity',
  RULES__FRAUD_BLOCKS__USER_PSP_ACCOUNT = '/fraud-block/user-psp-account',
  RULES__FRAUD_BLOCKS__FRAUD_PROVIDER_BLOCK = '/fraud-block/fraud-provider-block',
  RULES__FOREX = '/rules/forex',
  RULES__STATUS_CODE = '/rules/status-code',
  RULES__PAYMENT_METHODS = '/rules/payment-methods',
  RULES__ALERTS = '/rules/alerts',
  RULES__NOTIFICATION = '/rules/notification',
  RULES__APPROVAL = '/rules/approval',
  RULES__VAT = '/rules/vat',
  RULES__VOID_CAPTURE = '/rules/void-capture',
  ADMIN__BACKOFFICE_USERS = '/admin/backoffice-users',
  ADMIN__AUDIT_LOG = '/admin/audit-log',
  ADMIN__CONFIGURATION = '/admin/configuration',
  ADMIN__MERCHANT = '/admin/merchant',
  ADMIN__MAINTENANCE = '/admin/maintenance',
  ADMIN___API_CLIENTS = '/admin/api-clients',
  ADMIN__INVOICE = '/admin/invoice',
  ADMIN__CUSTOMER = '/admin/customer',
  ADMIN__TEMPLATES__EMAIL = '/templates/email',
  ADMIN__TEMPLATES__SCRIPT = '/templates/script',
  ADMIN__TEMPLATES__HTML = '/templates/html',
  ADMIN__TEMPLATES__SQL = '/templates/sql',
  ADMIN__TEMPLATES__MQ = '/templates/mq',
  ADMIN__TEMPLATES__CSS = '/templates/css',
  ADMIN__MESSAGES = '/admin/messages',
  ADMIN__FOREX_RATES = '/admin/forex-rates',
  ADMIN__IIN = '/admin/iin',
  ADMIN__BANK_MAPPING = '/admin/bank-mapping',
  ADMIN__CREDENTIALS_TEMPLATE = '/admin/credentials-template',
  ADMIN__CASHIER__PAYMENT_METHOD_LOGOS = '/admin/payment-methods/logos',
  ADMIN__CASHIER__PAYMENT_METHODS_AND_INPUTS = '/admin/payment-methods/inputs',
  ERROR = '*'
}

export enum LegacyPaths {
  HOME = '/',
  TRANSACTIONS = '/#transaction/search/',
  APPROVE = '/#approve/search/',
  INVESTIGATE = '/#investigate/search/',
  FINANCE__SETTLEMENTS = '/#settlement/search/',
  FINANCE__BALANCE = '/#balance/search/',
  ANALYTICS = '/#analytics',
  USER_ACCOUNTS = '/#user-psp-accounts/search/',
  KYC__SEARCH = '/#kyc/search',
  KYC__BLOCK = '/#T16-decisiontable/read',
  KYC__ROUTING = '/#T15-decisiontable/read',
  KYC__FALLBACK = '/#T22-decisiontable/read',
  TOOLS__STORE__PRODUCT = '/#product/read',
  TOOLS__STORE__VOUCHER = '/#voucher/read/',
  RULES__ROUTING__PSP_ROUTING = '/#T3-decisiontable/read',
  RULES__ROUTING__PSP_FALLBACK = '/#T6-decisiontable/read',
  RULES__ROUTING__FRAUD_PROVIDER = '/#T21-decisiontable/read',
  RULES__ROUTING__3DS2_ROUTING = '/#T28-decisiontable/read',
  RULES__ROUTING__3DS2_FALLBACK = '/#T29-decisiontable/read',
  RULES__FEES__FEE = '/#T4-decisiontable/read',
  RULES__FEES__PROVIDER_FEE = '/#T20-decisiontable/read',
  RULES__LIMIT = '/#T5-decisiontable/read',
  RULES__FRAUD_BLOCKS__GENERAL_BLOCK = '/#T27-decisiontable/read',
  RULES__FRAUD_BLOCKS__IP_BLOCK = '/#T1-decisiontable/read/',
  RULES__FRAUD_BLOCKS__BIN_BLOCK = '/#T2-decisiontable/read',
  RULES__FRAUD_BLOCKS__VELOCITY = '/#T7-decisiontable/read',
  RULES__FRAUD_BLOCKS__USER_PSP_ACCOUNT = '/#T19-decisiontable/read',
  RULES__FRAUD_BLOCKS__FRAUD_PROVIDER_BLOCK = '/#T24-decisiontable/read',
  RULES__FOREX = '/#T8-decisiontable/read',
  RULES__STATUS_CODE = '/#T11-decisiontable/read/',
  RULES__PAYMENT_METHODS = '/#T12-decisiontable/read/',
  RULES__ALERTS = '/#T13-decisiontable/read/',
  RULES__NOTIFICATION = '/#T14-decisiontable/read/',
  RULES__APPROVAL = '/#T18-decisiontable/read/',
  RULES__VAT = '/#T10-decisiontable/read/',
  RULES__VOID_CAPTURE = '/#T26-decisiontable/read/',
  ADMIN__AUDIT_LOG = '/#piqauditlog/search',
  ADMIN__CONFIGURATION = '/#config/read/',
  ADMIN__MERCHANT = '/#merchant/search',
  ADMIN__MAINTENANCE = '/#maintenance/search',
  ADMIN___API_CLIENTS = '/#adminapiclient/search',
  ADMIN__INVOICE = '/#invoice/search',
  ADMIN__CUSTOMER = '/#customer/search',
  ADMIN__TEMPLATES__EMAIL = '/#emailtemplate/read/',
  ADMIN__TEMPLATES__SCRIPT = '/#scripttemplate/read/',
  ADMIN__TEMPLATES__HTML = '/#htmltemplate/read/',
  ADMIN__TEMPLATES__SQL = '/#sqltemplate/read/',
  ADMIN__TEMPLATES__MQ = '/#mqtemplate/read/',
  ADMIN__TEMPLATES__CSS = '/#csstemplate/read/',
  ADMIN__MESSAGES = '/#i18nMessageGrid',
  ADMIN__FOREX_RATES = '/#forex',
  ADMIN__IIN = '/#issuerinfo/search',
  ADMIN__BANK_MAPPING = '/#bankmapping/search',
  ADMIN__CREDENTIALS_TEMPLATE = '/#credentialstemplate/read',
  ADMIN__RESOURCE_CENTER = '/#resourceCenter/read',
  ES_TRANSACTIONS = '/#es-transaction/search/',
  ES_APPROVE = '/#es-approve/search/',
  ES_INVESTIGATE = '/#es-investigate/search/',
}

export interface ExtendedRouteObject extends PageRoles {
  label: string | null;
  id: Pages;
  path: Paths;
  Component: FunctionalComponent<PageProps>;
  extraProps?: Record<string, unknown>;
  viewId?: Pages;
}
