interface BaseEnvironment {
  legacyBackofficeUrl: string;
  boApiEndpoint: string;
  piqApiEndpoint: string;
  documentationUrl: string;
  oldBoVersion: string;
  logoutUrl: string;
  aiqBoBaseUrl: string;
  aiqLandingPageUrl: string;
  mendableAnonKey: string;
  countlyAppKey: string;
  countlyUrl: string;
}

export enum EnvironmentTypes {
  PRODUCTION = 'production',
  TEST = 'staging',
  DEV = 'development',
}

export interface AppConfig {
  [EnvironmentTypes.PRODUCTION]: BaseEnvironment;
  [EnvironmentTypes.TEST]: BaseEnvironment;
  [EnvironmentTypes.DEV]: BaseEnvironment;
}
