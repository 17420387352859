import type { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { getEnvConfig } from 'utils/helpers';

const baseQueryConfig: FetchBaseQueryArgs = {
  baseUrl: getEnvConfig().boApiEndpoint,
};

/*
const adaptFetchBaseQuery = () => fetchBaseQuery({
  ...baseQueryConfig,
});
*/

const baseQuery = fetchBaseQuery(baseQueryConfig);

export default baseQuery;
