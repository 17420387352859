import type { PaymentIqRoles } from 'types/service/metadata';

export type UserRole = PaymentIqRoles | UserFlags;

export type UserRoles = UserRole[];

export enum UserFlags {
    IS_MASTER_MERCHANT = 'IS_MASTER_MERCHANT',
    HAS_MASTER_MERCHANT = 'HAS_MASTER_MERCHANT',
    IS_ANALYTICS_USER = 'IS_ANALYTICS_USER',
    IS_INTERNAL_USER = 'IS_INTERNAL_USER',
    IS_EXPERIMENTAL_USER = 'IS_EXPERIMENTAL_USER',
}
