const colors = {
  // Worldline
  masterMint: '#45beab',
  coolCoconut: '#ffffff',
  perfectPepper: '#000000',
  sureSage: '#2d8d8c',
  boldBlueberry: '#42b4d2',
  reliableRhubarb: '#f08890',
  confidentCorn: '#ffeb78',
  friendlyFig: '#005a8b',
  grey: '#5b5b5b',
  lightGrey: '#e2e2e2',
  lighterLightGrey: '#eeeeee',

  // Extra
  lightGreen: '#3cc47c',
  darkGreen: '#003533',
  darkAltGreen: 'rgb(31 53 45)',
  beige: '#e9c893',
  darkGrey: '#828081',
  red: '#e24e42',
  yellow: '#e9b000',
  purple: '#431c5d',
  white: '#ffffff',
  black: '#000000',
  dark: '#222222',
  borderColor: '#efefef',
  textGrey: '#575665',
  darkMint: '#007B7C',
  orange: '#DB6A00',
  lightMint: '#e5fbfa',
  transparentBlack: 'rgba(0, 0, 0, 0.5)',
  semanticTextBlack: 'rgba(0, 0, 0, 0.87)',
  semanticTextDisabled: 'rgba(0, 0, 0, 0.3)',
  semanticLightBackground: 'rgba(0, 0, 0, 0.05)',
  semanticRed: '#F08791',
  semanticBorderFocused: '#96C8DA',
  semanticBorder: 'rgba(34, 36, 38, 0.15)',
};

// Function to convert hex to RGBA
export function hexToRGBA(hex: string, alpha = 1) {
  hex = hex.replace(/^#/, '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default colors;
